<template>
	<div class="tag-content">
		<Nav></Nav>
		<TopCover :pageMsg='pageMsg'></TopCover>
		<TagC @getTagsData='getTagsData' @taged='taged' :flag='flag'></TagC>
		<el-row class="tag-all">
		  <el-col :span="8" v-for="(article, index) in tagsData[tag]" :key="index">
		    <el-card :body-style="{ padding: '0px' }">
		      <div style="-webkit-clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%)" v-show="index%2==0" class="div-type-img"><a :href="/article/+article.aid"><img v-lazy="article.topImg" class="image"></a></div>
		      <div style="padding: 14px;">
				<div class="msg" :class="index%2!=0?'even':''">
					  <span title="创建时间"><span><i class="el-icon-date"></i></span><time class="time" v-text="$moment(article.createTime).format('YYYY-MM-DD')"></time></span>
					  <span title="浏览量"><span><i class="el-icon-view"></i></span><span v-text="article.views"></span></span>
					  <span title="评论数"><span><i class="el-icon-chat-dot-round"></i></span><span v-text="article.countsComment"></span></span>
				</div>
		        <h3 v-text="article.title"></h3>
		        <div class="bottom clearfix" v-text="article.description">
		        </div>
		      </div>
			  <div style="-webkit-clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);" v-show="index%2!=0" class="div-type-img"><a :href="/article/+article.aid"><img v-lazy="article.topImg" class="image"></a></div>
		    </el-card>
		  </el-col>
		</el-row>
	</div>
</template>

<script>
import TopCover from '@/components/TopCover.vue'
import Nav from '@/components/Nav.vue'
import TagC from '@/components/Tag.vue'

export default {
  metaInfo: {
      title: '标签 - KASUIEの次元',
      meta: [
  	    {
  		    name: 'description',
              content: '文章标签',
  	    },
          {
              name: 'keywords',
              content: '文章标签，博客文章，标签，标签云'
          }
    ]
  },
  name: 'Tag',
  data() {
  	return {
		tagsData: [],
		tag: '',
		flag: true,
		pageMsg:{
			title: '标签云',
			imgUrl: 'https://i.loli.net/2021/08/18/WzmXt952jPqGiTn.jpg',
			article: ''
		},
	}
  },
  components: {
	TopCover,
	Nav,
	TagC
  },
  methods: {
	getTagsData(data){
		this.tagsData = data
	},
	taged(tag){
		this.tag = tag
	}
  },
  created() {
  	document.title = '标签 - KASUIEの次元'
	let title
	setTimeout(function() {
	   	title = document.title
	}, 1000);
	window.onfocus = function () {
		document.title = '恢复正常了...';
	    setTimeout(function() {
			document.title = title
		}, 1000);
	};
	window.onblur = function () {
		setTimeout(function() {
			document.title = '快回来~页面崩溃了'
		}, 5000);
	};
  },
  mounted() {
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},2000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}
</script>

<style>
  .tag-all{
	width: 60%;
	margin: 2em auto;
  }
  .tag-all>.el-col{
	  transition: all .3s ease-in-out;
  }
  .tag-all>.el-col>.el-card{
	  border: unset;
	  color: var(--theme-color);
	  background-color: var(--theme-background-color);
  }
  .image {
    width: 100%;
	height: 280px;
    display: block;
	transition: all .3s ease-in-out;
  }
  
  .el-col .el-card .el-card__body:hover .image{
	  transform: scale(1.2);
  }
  
  .tag-all>.el-col:hover{
	  box-shadow:0 2px 12px 0 rgb(0 0 0 / 50%);
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .el-row{
	display: flex;
	flex: auto;
	flex-wrap: wrap
  }
  .el-col-8{
	  width: 100%;
	  margin: 1em;
  }
  .el-card__body h2{
	  position: relative;
	  text-align: start;
	  margin-left: 1em;
	  text-indent: .5em;
	  transition: all .2s ease-in-out;
  }
  .el-card__body h2::before{
	  content: '';
	  position: absolute;
	  left: -1em;
	  top: .3em;
	  width: .6em;
	  height: .6em;
	  border: 3px solid #29BDFF;
	  border-radius: 50%;
  }
  .el-card__body h2:hover::before{
	  border: 3px solid pink;
  }
  .el-card__body h2:hover{
	  color: pink;
  }
  .el-col .el-card .el-card__body{
	padding: 0px;
	display: flex;
	flex: auto;
	flex-wrap: nowrap;
  }
  .el-col .el-card .el-card__body>div{
	  flex: auto;
	  width: 50%;
  }
  .el-col .el-card .el-card__body>div:nth-child(odd){
	  overflow: hidden;
  }
  .el-col .el-card .el-card__body div:nth-last-child(2){
	  text-align: center;
  }
  .el-col .el-card .el-card__body h3{
	  margin-top: 2em;
  }
  .msg{
	  text-align: end;
  }
  .msg span{
	  margin-right: .3em;
  }
  .even{
	  text-align: start;
  }
  @media only screen and (max-width: 770px){
	  .tag-all{
	  	width: 95%;
	  }
	  .el-card__body{
		  padding: 2px;
	  }
	  .el-col .el-card .el-card__body h3{
		  white-space: nowrap;
		  margin-top: 1em;
		  overflow: hidden;
		  text-overflow: ellipsis;
	  }
	  .el-col .el-card .el-card__body .bottom{
		  margin-top: 1em;
	  }
	  .el-timeline-item__wrapper{
		  padding-left: 1em;
	  }
	  .div-type-img{
		  display: none;
	  }
	  .msg{
		  text-align: start;
	  }
  }
</style>
