<template>
	<div class="tags-cloud" v-loading='loading'
	 element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
	element-loading-background="rgba(0, 0, 0, 0.8)">
		<h2>标签云 <span v-if="flag">----- {{ tagCloud[index] }}</span></h2>
		<div class="tags" v-if="tagCloud.length<100">
			<el-tag v-for="(atag,index) in tagCloud" :key='index' v-text="atag+'('+tagsData[atag].length+')'" :title="'该标签下有'+tagsData[atag].length+'篇文章'" @click='taged(index)' effect="dark" class="tag" :id="'tag-'+index" :color='color[Math.floor(Math.random() * color.length)]'></el-tag>
		</div>
		
	</div>
</template>

<script>
export default{
	data() {
		return {
			tagCloud: [],
			tagsData: [],
			tagFlag: false,
			index: 0,
			loading: true,
			types: ['','success','info','warning','danger'],
			color: ['#67c23a','#e6a23c','#409eff','#f379bb','#be6ae4','#52e0c7','#f3a954','#677ae4','#ad7c6a','#bd5b7c','#6f9cb1','#009688']
		}
	},
	props: ['flag'],
	methods: {
		getTags(){
			let that = this
			this.axios.get('getTags').then(function(results){
				that.tagsData = results.data
				for (let tag in that.tagsData) {
					that.tagCloud.push(tag)
				}
				that.$emit('getTagsData',that.tagsData)
				if(that.flag){
					that.taged(0)
				}
				if(that.$route.params.index){
					that.taged(that.$route.params.index)
				}
				that.loading = false
			},function(err){
				console.log(err);
			})
		},
		taged(index){
			// let tag = 'tag-'+ index.toString()
			// let el = document.getElementById(tag)
			// console.log(el);
			// console.log(index);
			// let el = document.getElementsByClassName('tag')
			// console.log(el);
			// el.style.textDecoration = 'underline'
			this.index = index
			if(this.flag){
				this.$emit('taged',this.tagCloud[index])
			}else{				
				this.$router.push({
					name: 'Tag',
					params: {
						index: index
					}
				})
			}
		}
	},
	created() {
		this.getTags()
	}
}
</script>

<style>
.tags-cloud{
	width: 60%;
	margin: 0 auto;
	min-height: 5em;
}
.tags-cloud h2{
	margin: 1em auto;
	color: #fff;
}
.tags-cloud .tag{
	margin: .5em;
	border-radius: 10px;
	cursor: pointer;
}
.darkApp .el-tag--dark{
	border-color: white;
}
@media screen and (max-width: 1000px) {
	.tags-cloud{
		width: 70%;
	}
}
@media screen and (max-width: 770px) {
	.tags-cloud{
		width: 100%;
	}
}
</style>
